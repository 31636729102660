<template>
  <v-container fluid class="pt-5 pb-8 greyBg analysis-container">
    <v-card elevation="0" class="pa-5">
      <AnalysisList />
    </v-card>
  </v-container>
</template>

<script>
import AnalysisList from "@/components/analysis/AnalysisList";

export default {
  created() {},
  components: {
    AnalysisList,
  },
};
</script>

<style>
.fixed-height {
  height: 70vh;
  min-height: 250px;
  min-width: 200px;
  padding: 15px;
}
.table {
  overflow: auto;
}
.analysis-container {
  min-height: 100vh;
  padding: 20px !important;
}
</style>